import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export const TilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Tile = styled(Link)`
  padding: 24px;
  min-width: 320px;
  background-color: var(--bg-up);
  flex: 1;
  border-radius: 16px;
  transition: transform 0.3s;
  will-change: transform;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  text-decoration: none;
  :hover {
    transform: scale(1.02);
  }
  :active {
    transform: scale(1);
  }
  @media (max-width: 640px) {
    padding: 16px;
    min-width: 240px;
  }
  time {
    color: var(--fg);
    font-size: 0.8em;
  }
`;

export const TileOutbound = styled(OutboundLink)`
  padding: 24px;
  min-width: 320px;
  background-color: var(--bg-up);
  flex: 1;
  border-radius: 16px;
  transition: transform 0.3s;
  will-change: transform;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  text-decoration: none;
  :hover {
    transform: scale(1.02);
  }
  :active {
    transform: scale(1);
  }
  @media (max-width: 640px) {
    padding: 16px;
    min-width: 240px;
  }
  time {
    color: var(--fg);
    font-size: 0.8em;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2, h3 {
    margin: 0px;
    text-decoration: underline;
    text-decoration-thickness: 0.02em;
    text-underline-offset: 0.15em;
  }
  p {
    color: var(--fg);
    margin: 0px;
    font-size: 0.8em;
  }
`;

export const IFrame = styled(motion.iframe).attrs(() => ({
  initial: { y: 200, opacity: 0 },
  exit: { y: 1000, opacity: 0 },
  animate: { y: 0, opacity: 1 },
}))`
  border: 0px;
  width: 42.68vw;
  height: 24vw;
  position: fixed;
  border-radius: 8px;
  right: 24px;
  bottom: 24px;
  z-index: 10000;
  box-shadow: 0px 8px 24px #00000040;
  background-color: #000;
  @media (max-width: 640px) {
    width: calc(100vw - 16px);
    height: 54.5vw;
    left: 8px;
    top: 8px;
    bottom: unset;
  }
`;

export const Video = styled.video.attrs(() => ({
  autoPlay: true,
  muted: true,
  controls: true,
}))`
  width: 100%;
  height: 800px;
`;

export const VideoSpacer = styled.div`
  @media (max-width: 640px) {
    height: 60vw;
  }
`;

export const Thumbnail = styled.img.attrs(() => ({
  alt: "",
}))`
  width: 100%;
  height: auto;
  max-width: 400px;
  object-fit: cover;
  margin-bottom: 8px;
  border-radius: 8px;
`;
