import * as React from "react";
import { AnimatePresence } from "framer-motion";
import ContextConsumer, { ContextProviderComponent } from "./context";
import { IFrame } from "../components/foundations";
import "../components/style.css";

const Layout = ({ children }) => {
  const [showPlayer, setShowPlayer] = React.useState(false);

  React.useEffect(() => setShowPlayer(true), []);
  
  return (
    <ContextProviderComponent>
      <ContextConsumer>
        {({ data }) => (
          <AnimatePresence>
            {data.ogVideo.url && showPlayer && (
              <IFrame
                title={data.ogTitle}
                type="text/html"
                src={`${data.ogVideo.url}?autoplay=1&mute=1&hl=ru&cc_lang_pref=ru&cc_load_policy=1&modestbranding=1&playsinline=1&loop=1`}
              />
            )}
          </AnimatePresence>
        )}
      </ContextConsumer>
      {children}
    </ContextProviderComponent>
  );
};

export default Layout;
